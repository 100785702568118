import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-12.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import newline1 from "../../assets/images/sp/12/newline-1.jpg";
import newline2 from "../../assets/images/sp/12/newline-2.jpg";
import newline3 from "../../assets/images/sp/12/newline-3.jpg";
import newline4 from "../../assets/images/sp/12/newline-4.jpg";
import newline5 from "../../assets/images/sp/12/newline-5.jpg";
import newline6 from "../../assets/images/sp/12/newline-6.jpg";
import newline7 from "../../assets/images/sp/12/newline-7.jpg";
import newline8 from "../../assets/images/sp/12/newline-8.jpg";
import newline9 from "../../assets/images/sp/12/newline-9.jpg";
import newline10 from "../../assets/images/sp/12/newline-10.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`New Line | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 12</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.new-line.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify mb-4">
                                <h6 className="text-primary text-uppercase fw-bold">30 anni di passione e dedizione</h6>
                                <h1>New Line</h1>
                                <p> Nel 1988 la famiglia Comi inizia l'attività in Canton Ticino aprendo il primo
                                    negozio New Line.</p>
                                <p>Partendo da un piccolo negozio in centro Lugano di soli 80 mq e qualche
                                    collaboratore, piano piano cresciamo e nel 1997 raddoppiamo i nostri spazi
                                    espositivi completando la nostra gamma di prodotti e il numero dei 20
                                    collaboratori.</p>
                                <p>Nel 2005 arriva la prima grande sfida: ci spostiamo nell'Openspace di Pambio
                                    Noranco, con 1400mq e diverse decine di collaboratori.
                                    Grazie anche alla presenza dei figli in azienda, l'evoluzione continua e nel
                                    2015
                                    nasce Newline Group con 5 divisioni: Store, Project, Service, Contract, Real
                                    Estate. Oggi il team conta più di 60 professionisti. </p>
                                <p>“Da oltre 25 anni lavoriamo con passione e dedizione, per accompagnare il cliente
                                    nella ristrutturazione dei suoi luoghi di vita. Crediamo fortemente nel lavoro di
                                    squadra: per questo il nostro successo è quello di ciascuno dei nostri
                                    professionisti che danno un valore aggiunto all’azienda. La nostra unica missione?
                                    Individuare la soluzione ideale per tutte le persone che si affidano a noi; con loro
                                    costruiamo, giorno dopo giorno, un solido rapporto di fiducia”.</p>
                                <Divider/>
                                <h6 className="text-primary text-uppercase fw-bold">Il nostro motto</h6>
                                <h4>"Scegli un lavoro che ami, e non dovrai lavorare neppure un
                                giorno
                                in vita tua." </h4><i>Confucio</i>
                        </Col>
                        <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-center">
                            <Carousel autoplay={3500} effect="fade">
                                <div>
                                    <img src={newline1} alt="New Line"
                                         className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                </div>
                                <div>
                                    <img src={newline2} alt="New Line"
                                         className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                </div>
                                <div>
                                    <img src={newline3} alt="New Line"
                                         className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                </div>
                            </Carousel>
                            <h6>Giampiero Comi, Andrea Comi e Stefano Comi</h6>
                        </Col>
                    </Row>
            </div>
        </section>
    <section>
        <div className="container">
            <Row gutter={{xs: 16, lg: 48}} align="top">
                <Col xs={{span: 24, order: 2}} lg={{span: 24, order: 1}} className="text-center">
                    <h2>Alcuni dei progetti firmati New Line</h2>
                    <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                        <div>
                        <img src={newline4} alt="New Line"
                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                        </div>
                        <div>
                        <img src={newline5} alt="New Line"
                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                        </div>
                        <div>
                        <img src={newline6} alt="New Line"
                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                        </div>
                        <div>
                        <img src={newline7} alt="New Line"
                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                        </div>
                        <div>
                        <img src={newline8} alt="New Line"
                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                        </div>
                        <div>
                        <img src={newline9} alt="New Line"
                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                        </div>
                        <div>
                        <img src={newline10} alt="New Line"
                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                        </div>
                    </Carousel>
                </Col>
            </Row>
        </div>
    </section>
    <section className="bg-secondary">
        <div className="container text-center">
            <div className="youtubeContainer">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/nhgW7tAFQhw"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
            </div>
        </div>
    </section>
</div>
</Layout>
)
}

export default SponsorPage
